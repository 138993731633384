<template>
  <b-card>
    <slot name="prependFilters" />

    <div class="tab-content">
      <div class="mb-1 position-absolute loader">
        <b-spinner label="Loading..." v-if="loading" />
      </div>

      <vue-good-table
        :key="count + loading + limit"
        styleClass="vgt-table striped"
        class="overflow-scroll hide-scroll"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :pagination-options="{
          enabled: isPagination,
          perPage: count,
        }"
        :sort-options="{
          enabled: false,
        }"
      >
        <template #table-row="{ column, row, formattedRow } = {}">
          <slot :column="column" :row="row" :formattedRow="formattedRow">
            <span class="flex-center-align">
              {{ formattedRow[column.field] }}
            </span>
          </slot>
        </template>

        <!-- pagination -->
        <template #pagination-bottom v-if="isPagination">
          <div class="d-flex  justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select
                v-model="selectPerPage"
                :options="rowsPerPageOptions"
                class="mx-1"
                @change="changePageLimit"
              />
              <span class="text-nowrap ">
                Rows per table
              </span>
            </div>

            <div>
              <b-pagination
                :value="page"
                :total-rows="count"
                :per-page="limit"
                class="mt-1 mb-0"
                last-number
                @change="changePage"
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                first-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>
<script>
import { BCard, BFormSelect, BPagination, BSpinner } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'

export default {
  name: 'AppTable',
  components: {
    BCard,
    BFormSelect,
    BPagination,
    BSpinner,
    VueGoodTable,
  },
  props: {
    isPagination: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        count: 0,
        limit: 0,
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],
    }
  },

  computed: {
    direction() {
      return store.state.appConfig.isRTL
    },

    loading() {
      return this.isLoading
    },

    count() {
      return this.pagination.count
    },

    limit() {
      return this.pagination.limit
    },

    page() {
      return this.pagination.page
    },
  },

  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber
      this.$emit('change-page', pageNumber)
    },

    changePageLimit(pageLimit) {
      this.$emit('change-limit', pageLimit)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.loader {
  z-index: 1;
  top: min(50vh, 50%);
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
